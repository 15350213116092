import { postDataRoles } from "./requests";

export const getJobRolesByCat = (_cat, _mode, _type) => {
    const url = 'api/admin/onboarding/getjobroleoptions';
    const requestData = {
        'category': _cat,
        'mode': _mode,
        'type': _type
    };
    return postDataRoles(url, requestData)
}