import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import GoBack from "../../../../components/GoBack";
import LocationSearch from "../../locationAutoSuggestion/autosugestion";
import {API_JOBPOSTER_COMPANY_ADD_OR_EDIT, API_JOBPOSTER_COMPANY_DELETE, postApiPosterMultipart} from "../../../../utils";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import Swal from "sweetalert2";

export default function AddOrEditCompanyPage() {
	const navigate = useNavigate();
	const {state} = useLocation();

	const userToken = useSelector((state) => state.UserAuth.userData.payload.token);
	const userId = useSelector((state) => state.UserAuth.userData.payload.user._id);

	const [isLoading, setIsLoading] = useState(false);

	const [companyId, setCompanyId] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [companyDescription, setCompanyDescription] = useState('');
	const [companySize, setCompanySize] = useState([]);
	const [listedCompanySize, setListedCompanySize] = useState('');
	const [locationSelected, setLocationSelected] = useState('');
	const [fileName, setFileName] = useState("");
	const [documentSelected, setDocumentSelected] = useState("");

	const companySizes = [
		{id: '1', size: '1-20'},
		{id: '2', size: '21-50'},
		{id: '3', size: '51-100'},
		{id: '4', size: '101-250'},
		{id: '5', size: '251+'},
	];
	useEffect(() => {
		setListedCompanySize(companySizes);

		if(state) {
			setCompanyId(state._id);
			setCompanyName(state.name);
			setCompanyDescription(state.description);
			setCompanySize(companySizes.filter(e => e.size === state.size)[0].id);
			setLocationSelected(state.location);
		}
	}, []);

	const getLocationSelected = location => {
		setLocationSelected(location);
	};

	const getFileSelected = (e) => {
		const fileName = e.target.files[0].name;
		const fileSelected = e.target.files[0];
		setFileName(fileName);
		setDocumentSelected(fileSelected);
	};

	const addCompany = () => {
		if((companyId && companyName && companyDescription && companySize && locationSelected) || ((!companyId) && companyName && companyDescription && companySize && locationSelected)) {
			const theSize = companySizes.filter(e => e.id == companySize)[0].size;

			var formdata = new FormData();
			formdata.append("userId", userId);
			if(companyId) {
				formdata.append("companyId", companyId);
			}
			formdata.append("companyName", companyName);
			formdata.append("companyDescription", companyDescription);
			formdata.append("companySize", theSize);
			formdata.append("companyLocation", locationSelected);
			if(documentSelected) {
				formdata.append("companyLogo", documentSelected);
			}

			postApiPosterMultipart(
				API_JOBPOSTER_COMPANY_ADD_OR_EDIT,
				formdata,
				userToken,
				companyAddSuccessful,
				companyAddError
			);
		} else {
			toast.error('Oops! something went wrong!', {
				position: 'top-right',
				autoClose: 1000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};

	const companyAddSuccessful = () => {
		navigate(companyId ? "../companies" : "../companies");
	};

	const companyAddError = () => {
		toast.error('Oops! something went wrong!', {
			position: 'top-right',
			autoClose: 1000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	const confirmDeleteCompany = () => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes"
		}).then((result) => {
			if (result.isConfirmed) {
				deleteCompany();
			}
		});
	};

	const deleteCompany = () => {
		setIsLoading(true);
		postApiPosterMultipart(
			API_JOBPOSTER_COMPANY_DELETE,
			{"userId": userId, "companyId": companyId},
			userToken,
			companyDeleteSuccessful,
			companyDeleteError
		);
	};

	const companyDeleteSuccessful = () => {
		navigate("../../");
		toast.success('Company deleted!', {
			position: 'top-right',
			autoClose: 1000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};
	const companyDeleteError = () => {
		toast.error('Oops! something went wrong!', {
			position: 'top-right',
			autoClose: 1000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
		setIsLoading(false);
	};

	return (
		<React.Fragment>
			{isLoading && (
				<div className="d-flex justify-content-center align-items-center LoaderCenter">
					{" "}
					<div className="block">
						<span
							className="spinner-grow spinner-grow-lg mx-2"
							role="status"
							aria-hidden="true"
						/>
						<h6 className="LoaderText">Processing...</h6>
					</div>
				</div>
			)}
			{!isLoading && (
				<div>
					<GoBack></GoBack>
					<div className="container">
						<div className="row">
							<div className="col-lg-12 text-center mb-3">
								<h3>Tell us about the company</h3>
							</div>
						</div>

						<div className="row">
							<div className="col-lg-2"/>
							<div className="col-lg-8 text-center">
								<h4 style={{fontSize: '24px', lineHeight: '26px'}}>Company Name</h4>
								<input type="text" id="name" name="name" className="job-input"
									value={companyName} onFocus={event => {event.target.setAttribute('autocomplete', 'off');}}
									placeholder="Company Name" onChange={event => {setCompanyName(event.target.value);}}
									style={{width: '100%'}}/>
							</div>
						</div>

						<div className="row mt-4">
							<div className="col-lg-2"/>
							<div className="col-lg-8 text-center">
								<h4 style={{fontSize: '24px', lineHeight: '26px'}}>Company Description</h4>
								<p style={{fontSize: '1em', lineHeight: '20px'}} className={"mb-0"}>
									Try not to overthink just be yourself and tell us a bit
									about the company and why this is a great opportunity for
									potential candidates.
								</p>
								<div className="text-area">
								<textarea
									style={{height: '80px', border: '1px solid #c1c1c1', borderRadius: '5px', fontSize: '16px'}}
									className={"mb-0"}
									placeholder="Tell us more about your company..."
									value={companyDescription}
									onChange={(e) => setCompanyDescription(e.target.value)}/>
								</div>
							</div>
						</div>

						<div className="row justify-content-center mt-4">
							<div className="col-lg-2"/>
							<div className="col-lg-8">
								<div className="card" style={{borderWidth: 0}}>
									<form id="msform">
										<fieldset>
											<div>
												<h4 style={{fontSize: '24px', lineHeight: '26px'}}>Which describes your company size best?</h4>
												<div className="role-wrapper">
													{listedCompanySize &&
														listedCompanySize.map((data, i) => (
															<div key={i} className="role-col">
																<div key={i + 2} className="inputGroup">
																	<input key={i + 3} id={data?._id} name="radio" type="radio" 
																	readOnly checked={companySize.includes(data.id)}
																		// onChange={() => setCompanySize([ data.id ])}
																	/>
																	<label key={i + 4} onClick={() => setCompanySize([data.id])} htmlFor={data?._id}>
																		{data.size}&nbsp;employees
																	</label>
																</div>
															</div>
														))}
												</div>
											</div>
										</fieldset>
									</form>
								</div>
							</div>
							<div className="col-lg-2"/>
						</div>

						<div className='row mt-4'>
							<div className='col-lg-2' />
							<div className='col-lg-8'>
								<div className='card' style={{ borderWidth: 0 }}>
									<form id='msform'>
										<fieldset>
											<div className='form-card'>
												<h4 style={{fontSize: '24px', lineHeight: '26px'}}>Where is the company located?</h4>
												<div className='enter-job-role' style={{transform: 'translateY(15%)', marginTop: '0'}}>
													<LocationSearch address={locationSelected} onLocationSelected={getLocationSelected} />
												</div>
											</div>
										</fieldset>
									</form>
								</div>
							</div>
						</div>

						<div className='row mt-5'>
							<div className="col-lg-12 text-center">
								<h4 style={{fontSize: '24px', lineHeight: '26px'}}>Company Logo</h4>
								{state?.logo && (
									<div style={{marginBottom: '10px'}}>
										<img src={state.logo} alt={"Company Logo"} style={{width: '150px', height: '150px', borderRadius: '75px', border: '1px solid #b8b8b8'}}/>
									</div>
								)}
								<div className="form-group upload-cv-col" style={{ padding: 0, margin: 0, width: 300, display: 'inline-block' }}>
									<div className="input-group">
										<div className="custom-file" style={{border: '1px solid #b8b8b8'}}>
											<div>
												<p style={{color: fileName ? 'black' : '#8b8b8b'}}>{fileName ? fileName : state?._id ? "Change Company Logo" : "Select Company Logo"}</p>
												<input type="file" accept=".jpg,.png,.jpeg" className="custom-file-input" id="inputGroupFile01"
												aria-describedby="inputGroupFileAddon01" onChange={(e) => getFileSelected(e)} />
											</div>
											<label className="custom-file-label" for="inputGroupFile01">
												<span className="hidden-xs">Browse</span>
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row mt-5">
							<div className="col-12">
								<button className="perspectv-button" style={{background: '#1ec991'}} onClick={addCompany}>{companyId ? 'Edit' : 'Add'} Company</button>
								<div style={{display: "inline-block", width: "20px"}}></div>
								{companyId ? <button className="perspectv-button" style={{background: '#ff0e0e'}} onClick={confirmDeleteCompany}>Delete Company</button> : null}
							</div>
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	);
}