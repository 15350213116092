import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import EagleMan from "../../../assets/images/eagle-man-img.png";
import HeaderMenu from "../../../components/HeaderMenu/headerMenu";
import { GetProfileData as GetProfileDataApi } from "../../../api/users";
import { getMatchesForSeeker as getMatchesForSeekerApi } from "../../../api/matches";
import { postApi, postApiPoster, API_JOBSEEKER_MATCH, API_JOB_ADD_WISHLIST } from "../../../utils";

const sideMatchingArray = [
    {
        title: "Current Matches",
        color: "#1ec991",
        type: "CM",
        heading: "Your Current Matches",
        noneToShowText: "Welcome to your Current Matches! While we don't currently have any Jobs that match your requirements, rest assured that we're actively sourcing new jobs every day.",
    },
    {
        title: "Interested",
        color: "#5e43f8",
        type: "IN",
        heading: "Your Interested Matches",
        noneToShowText: "Your Interested Matches section is where you'll find all the Employers who have expressed interest in you, as well as any jobs you've shown interest in.",
    },
    {
        title: "Mutual Interest",
        color: "#ffdb59",
        type: "MI",
        heading: "Mutual Interest Matches",
        noneToShowText: "At this moment, no roles are awaiting mutual interest from both you and potential employers. Once you have mutual interest the employer will select to unlock your profile and short list you.",
    },
    {
        title: "Short Listed",
        color: "#d9453d",
        type: "SL",
        heading: "Your Shortlisted Jobs",
        noneToShowText: "While there are no jobs you have been shortlisted for just yet, don't worry — we're continuously updating employers. Keep an eye on your current & interested matches for new opportunities.",
    },
];

const MatchesFound = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { id } = useParams();
    const [pageMounted, setPageMounted] = useState(false);
    const userType = useSelector((state) => state.userType.userType);
    const userToken = useSelector((state) => state.UserAuth.userData.payload.token);
    const userId = useSelector((state) => state.UserAuth.userData.payload.user._id);
    const [userDataFull, setUserDataFull] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState(sideMatchingArray[0]);
    const [selectedProfile, setSelectedProfile] = useState(sideMatchingArray[0].title);
    const [jobMatches, setJobMatches] = useState(false);
    const pageSize = 10;

    useEffect(() => {
        if (!pageMounted) {
            setPageMounted(true);
            loadUserData();
            if(['cm', 'in', 'mi', 'sl'].includes(id)){
                loadMatches(id.toLocaleUpperCase());
            }else{
                loadMatches("CM");
            }
        }
    }, [pageMounted]);

    const loadUserData = () => {
        const type = userType.payload === "jobSeeker" ? 1 : 2;
        GetProfileDataApi(userId, type)
            .then((_resp) => {
                const data = _resp.data.data;
                if ("_id" in data) {
                    setUserDataFull(data);
                }
            })
            .catch((_resp) => {
                console.log(_resp);
            });
    };

    const loadMatches = (_filter) => {
        if (_filter !== "") {
            const x = sideMatchingArray.find((item) => item.type === _filter);
            setSelectedMenuItem(x);
            setSelectedProfile(x.title);
        }
        getMatchesForSeekerApi(userId, _filter)
            .then((_resp) => {
                const matches = _resp.data.matches;
                setJobMatches(matches);
                console.log(_resp);
            })
            .catch((_resp) => {
                console.log(_resp);
            });
    };

    const renderItem = (item) => {
        const percent = item?.percentage.toFixed(0);
        const info = item?.info;
        const jobTitle = item?.info?.jobTitle?.option;
        const circleColor = item?.percentage >= 60 ? "#1ec991" : item?.percentage >= 50 ? "#5e43f8" : "#d9453d";
        return (
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12" key={item.id}>
                <div className="match-card" style={{ cursor: "pointer" }} onClick={() => navigate("../jobOffer", { state: { ...item, selectedProfile } })}>
                    <div className="match-card-content">
                        <div className="match-card-info">
                            <div><span className="capitalize">{jobTitle}</span>&nbsp;&ndash;&nbsp;{info?.locationName ?? ''}</div>
                        </div>
                        <div className="match-card-percent">
                            <div className="match-card-percent-container" style={{ background: circleColor }}>{percent}%</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderSidebarItem = (item) => (
        <button key={item.type} id={`btn-tab-${item.type}`} className="matching-button" style={{ backgroundColor: item.color }} onClick={() => loadMatches(item.type)}>
            {item.title}
        </button>
    );

    const renderMainButton = (item) => (
        <div className="row d-md-block">
            <div className="col-lg-12 mt-3">
                <a style={{ color: item.color, textDecoration: "none", fontWeight: "bold", fontSize: "2em" }} onClick={() => loadMatches(item.type)}>
                    {item.heading}
                </a>
            </div>
        </div>
    );

    const onBoostProfileClick = () => {
        navigate("../boostProfile");
    };

    return (
        <div className="position-relative">
            <HeaderMenu />
            {userDataFull !== null ? (
                <>
                    <section id="dashboard-cnt-area" className="dashboard-bg">
                        <div className="container-80">
                            <div className="row dashboard-cnt-row">
                                <div className="col-lg-3 col-sm-4 col-xs-12 dashboard-sidebar">
                                    <div className="row" style={{ paddingRight: 40 }}>
                                        {sideMatchingArray.map(renderSidebarItem)}
                                    </div>
                                </div>
                                {isLoading ? (
                                    <div className="d-flex justify-content-center align-items-center LoaderCenter">
                                        <div className="block">
                                            <span className="spinner-grow spinner-grow-lg mx-2" role="status" aria-hidden="true" />
                                            <h6 className="LoaderText">Processing...</h6>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-lg-9 col-sm-8 col-xs-12">
                                        <div className="row">
                                            <div className="col-lg-12 mb-4 d-flex">
                                                <div>{renderMainButton(selectedMenuItem)}</div>
                                            </div>
                                        </div>
                                        {!userDataFull["isPaid"] && (
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-10 col-md-12 col-sm-12 col-xs-12">
                                                    <div onClick={onBoostProfileClick}
                                                        className="match-card d-flex justify-content-start align-items-center bg-danger"
                                                        style={{ cursor: "pointer", textAlign: "center" }}
                                                    >
                                                        <div className="align-items-center">
                                                            <img src="/old-bomb-cloud.png" className="img-fluid" style={{ height: "65px", width: "65px" }} alt="" />
                                                        </div>
                                                        <div className="d-flex justify-content-start align-items-end bg-danger font-space-mono">
                                                            <h4 className="mb-0 me-1 boost-yellow">
                                                                <b className="ms-2">
                                                                    <u>BOOST</u>{" "}
                                                                </b>
                                                            </h4>
                                                            <h6 className="mb-0 text-white text-nowrap ms-4">your Profile</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {userType["payload"] === "jobSeeker" && (
                                            <div className="row">
                                                {jobMatches.length > 0 ? jobMatches.map(renderItem) : (
                                                    <p style={{ fontSize: "1em", textAlign: "start", fontFamily: "Space Mono, monospace" }}>
                                                        {selectedMenuItem.noneToShowText}
                                                    </p>
                                                )}
                                            </div>
                                        )}
                                        {selectedProfile === "Mutual Interest" && userType.payload !== "jobSeeker" && (
                                            <button
                                                onClick={() => navigate("../wishlist", { state })}
                                                className="matching-button talent-list-btn"
                                                style={{ backgroundColor: "#02add4", fontSize: "20px" }}
                                            >
                                                Talent List
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                    <footer className="dashboard-footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-xs-6 col-12 eagle-man-col">
                                    <img src={EagleMan} alt="Eagle Man" />
                                </div>
                                <div className="col-lg-8 col-sm-12 col-xs-12 col-12 text-center">
                                    <p>Copyright © 2023 perspectv.com. All Rights Reserved.</p>
                                </div>
                            </div>
                        </div>
                    </footer>
                </>
            ) : (
                <h1 className="opacity-30" style={{height: '600px', paddingTop: '200px'}}>Loading...</h1>
            )}
        </div>
    );
};

export default MatchesFound;
