import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import GoBack from "../../../../components/GoBack";
import PlusBtn from "../../../../assets/images/plus-btn.png";
import {useSelector} from "react-redux";
import {API_GET_POSTER_DETAIL, API_JOBPOSTER_COMPANY_GET, API_JOBPOSTER_COMPANY_GET_JOB_LIST, postApiPoster} from "../../../../utils";
import {toast} from "react-toastify";
import EditButton from "../../../../assets/images/edit-icon.png";
import logo from "../../../../assets/images/right-arrow.png";

export default function CompanyPage() {
	const {id} = useParams();
	const navigate = useNavigate();
	const {state} = useLocation();
	const [company, setCompany] = useState(state);

	const [loading, setLoading] = useState(true);

	const [jobList, setJobList] = useState([]);

	const userToken = useSelector(state => state.UserAuth.userData.payload.token);

	const requestDataFromServer = () => {
		if (!company) {
			loadCompanyData();
		} else {
			loadJobList()
		}
	};

	const loadCompanyData = () => {
		const params = {
			companyId: id,
		};
		postApiPoster(API_JOBPOSTER_COMPANY_GET, params, userToken, companySuccessFunction, errorFunction);
	};

	const companySuccessFunction = (res) => {
		setCompany(res.data.result);
		loadJobList();
	};

	const loadJobList = () => {
		const params = {
			companyId: id,
		};
		postApiPoster(API_JOBPOSTER_COMPANY_GET_JOB_LIST, params, userToken, successFunction, errorFunction);
	};

	const successFunction = res => {
		console.log(res, 'res');
		setLoading(false);
		setJobList(res.data.result);
	};
	const errorFunction = () => {
		toast.error('Oops! something went wrong!', {
			position: 'top-right',
			autoClose: 1000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	useEffect(() => {
		requestDataFromServer();
	}, []);

	const selectJob = (job) => {
		if (job.isJobCreated) {
			navigate('./' + job._id, {state: {job: job, company: company}});
			// Use this to test the jobDetails page
			// navigate('../../jobDetails', {state: {jobId: job._id}})
		} else {
			let state = {state: {companyId: id, jobId: job._id}};
			switch (job.selfAssessmentResultSteps) {
				case 1:
					navigate("../../jobTitle", state);
					break;
				case 2:
					navigate("../../chooseIndustry", state);
					break;
				case 3:
					navigate("../../educationLevel", state);
					break;
				case 4:
					navigate("../../skillLevel", state);
					break;
				case 5:
					navigate("../../location", state);
					break;
				case 6:
					navigate("../../companySize", state);
					break;
				case 7:
					navigate("../../salary", state);
					break;
				case 8:
					navigate("../../skillStep", state);
					break;
				case 9:
					navigate("../../softSkillStep", state);
					break;
				case 10:
					navigate("../../selfAssest", state);
					break;
				case 11:
					navigate("../../hardStep1", state);
					break;
				case 12:
					navigate("../../hardStep2", state);
					break;
				case 13:
					navigate("../../hardStep3", state);
					break;
				case 14:
					navigate("../../hardStep4", state);
					break;
			}
		}
	};

	return (<React.Fragment>
		{loading ? (
			<div className="d-flex justify-content-center align-items-center LoaderCenter">
				{' '}
				<div className="block">
					<span className="spinner-grow spinner-grow-lg mx-2" role="status" aria-hidden="true"/>
					<h6 className="LoaderText">Processing...</h6>
				</div>
			</div>
		) : (
			<div>
				<GoBack/>
				<div className="container">
					<div className="row mt-3">
						<div className="col-2"/>
						<div className="col-8" style={{textAlign: "start", display: 'flex', flexDirection: 'row', gap: "40px", alignItems: "center"}}>
							<div className="perspectv-header" style={{background: '#1ec991'}}>Current Job List</div>
							<span style={{fontFamily: "'Space Mono', monospace"}}>{company.name}</span>
						</div>
					</div>
					<div className="row">
						<div className="col-2"/>
						<div className="col-8">
							<div className="container">
								<div className="row dashboard-cnt-row d-flex align-items-center justify-content-center">
									<div className="col-lg-12 col-sm-12 col-xs-12 mircroshoft-cnt " style={{paddingTop: '0'}}>
									<div className="row mt-5 pb-5">
										{jobList?.length > 0 ? (
											jobList.map((job) => (
											<div key={job._id} className="col-lg-6 col-md-12 col-sm-12 col-xs-12 pb-2">
												<div className="current-job-col" onClick={() => selectJob(job)}>
												<div className="col-12 d-flex justify-content-between">
													{job.isJobCreated ? (
													<>
														<img style={{ height: "20px" }} src={EditButton} alt="Edit" onClick={(e) => {e.stopPropagation();navigate("../../jobDescription", { state: job });}}/>
														<div className="col-10">
															<h5 className="capitalize">
																{job.jobTitle?.option || "Job Title Not Set"}
															</h5>
															<p className="capitalize">{job.locationName || ""}</p>
														</div>
													</>
													) : (
													<div className="col-10">
														<h5>{job.jobTitle?.option || "Job Title Not Set"}</h5>
														<p style={{ color: "red" }}>Draft</p>
													</div>
													)}
													<img style={{ height: "20px" }} src={logo} alt="Details" />
												</div>
												</div>
											</div>
											))
										) : (
											<p>No jobs found.</p>
										)}
										</div>
										<div className="row">
											<div className="col-lg-12 mt-3 text-center mb-5">
												<a  onClick={() => navigate('/jobRole', {state: {companyId: id}})}>
													<img src={PlusBtn}/>{' '}
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
		}
	</React.Fragment>);
}